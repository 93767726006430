@import '@fontsource/inter/latin-600.css';
@import '@fontsource/raleway/latin-600.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* @import './base.css'; */

/* @import './utilities.css'; */

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/* Add this in your global CSS file */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}
.scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
