/* WorkflowCreation.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.workflow-container {
    height: 120vh;
    background: linear-gradient(to bottom right, red, blue);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.glint-text {
    font-family: 'Raleway', sans-serif;
    font-size: 40px;
    color: white;
    opacity: 0;
    animation: fadeIn 6s forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Container Styles */
.connect-container,
.connected-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.workflow {
    width: 300px;
    height: 200px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #333;
    /* Light black / dark gray background color */
    color: white;
    /* Text color */
    border: none;
    border-radius: 5px;
}

.workflow:focus {
    outline: none;
    /* Remove focus outline on the textarea */
}

.connect-button,
.submit-button-black {
    width: 200px;
    height: 20px;
    background-color: black;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.connect-button:hover,
.submit-button-black:hover {
    background-color: #333;
    /* Darker color on hover */
}

/* Connected Styles */
.connected-text {
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.green-dot {
    width: 10px;
    height: 10px;
    background-color: #4caf50;
    /* Green color */
    border-radius: 50%;
    margin-right: 5px;
}

.orange-dot {
    width: 10px;
    height: 10px;
    background-color: #f58a5f;
    /* Orange color */
    border-radius: 50%;
    margin-right: 5px;
}

.red-dot {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    margin-right: 5px;
}

/* Error Styles */
.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.error-message {
    font-size: 16px;
    color: red;
}

/* Connectivity Status Display */
.connectivity-status {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    color: black;
}

.connectivity-status p {
    margin: 0;
    padding-right: 10px;
}

/* Center Submit Button */
.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    position: absolute;
    top: 100px;
    left: 50%;
    /* Position the logo at the horizontal center of the page */
    transform: translateX(-50%);
    /* Center the logo horizontally */
    z-index: 1;
    /* Ensure the logo is displayed above other elements */
    width: 150px;
    /* Make the logo 1.5 times larger */
    height: 150px;
    /* Make the logo 1.5 times larger */
}

.start-page-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.small-input {
    width: 200px;
    height: 50px;
}

.workflow-container-my-workflows {
    padding-top: 100px;
    height: 100vh;
    background: linear-gradient(to bottom right, red, blue);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
